module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MDM house - Ремонт квартир в Таганроге","short_name":"MDM house","start_url":"/","background_color":"#3378b7","theme_color":"#3378b7","display":"minimal-ui","icon":"src/assets/images/website-icon.png","icons":[{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-384x384.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1836066d582287953eac73678b53c24b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-181815949-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"71337346","webvisor":true,"trackHash":true,"afterBody":true,"defer":false,"useCDN":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
