import React from 'react';
import ym from 'react-yandex-metrika';
import CallRoundedIcon from '@material-ui/icons/CallRounded';

import styles from './callback.module.scss';

const Callback = () => {
  return(
    <a href='tel: 89885359001' onClick={()=>{ym('reachGoal','klik_po_nomeru_telefona')}} className={styles.btnWrap}>
      <CallRoundedIcon />
    </a>
  )
}

export default Callback;
