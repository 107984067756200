import React, { useCallback, useLayoutEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core'
import { Favorite } from '@material-ui/icons'

import Logo from '../../assets/images/website-icon.png'

import styles from './styles.module.scss'

const Footer = () => {
  const [openLinks, setOpenLinks] = useState(true)
  const [openSocial, setOpenSocial] = useState(true)
  const [openContact, setOpenContact] = useState(true)
  const [windowWidth, setWindowWith] = useState(undefined)

  const handleResizeFooter = useCallback(() => {
    setWindowWith(window.innerWidth)
  }, [])
  useLayoutEffect(() => {
    handleResizeFooter()
    window.addEventListener('resize', handleResizeFooter)
    return () => {
      window.removeEventListener('resize', handleResizeFooter)
    }
  }, [handleResizeFooter])

  const mobileWidth = windowWidth && windowWidth < 1000

  const handleClickLinks = () => {
    setOpenLinks(!openLinks)
  }

  const handleClickSocial = () => {
    setOpenSocial(!openSocial)
  }

  const handleClickContact = () => {
    setOpenContact(!openContact)
  }

  const item = (title, link) => {
    if (link) {
      return (
        <Link to={link}>
          <li>{title}</li>
        </Link>
      )
    } else {
      return <li>{title}</li>
    }
  }

  const itemMobile = (title, link) => {
    if (link) {
      return (
        <Link to={link}>
          <ListItem className={styles.pl40}>{title}</ListItem>
        </Link>
      )
    } else {
      return (
        <ListItem>
          <span className={styles.pl40}>{title}</span>
        </ListItem>
      )
    }
  }

  return !mobileWidth ? (
    <footer className={styles.footer}>
      <div className={styles.footer__main}>
        <div className={styles.footer__main__logo}>
          <img src={Logo} alt="MDM House company logo with text" />
        </div>
        <div className={styles.footer__main__contacts}>
          <div className={styles.footer__text}>Контакты</div>
          <ul>
            {item('г. Таганрог, Россия')}
            {item('8 988 535 90 01')}
            {item('mdmhouse@mail.ru')}
            {item('ООО Натур')}
            {item('ИНН 6154153495')}
            {item('ОГРН 118619604097')}
            {item('Политика конфиденциальности','/politika-konfidenczialnosti/')}
          </ul>
        </div>
        <div className={styles.footer__main__links}>
          <div className={styles.footer__text}>Ссылки</div>
          <ul>
            {item('Главная', '/')}
            {item('О нас', '/#about')}
            {item('Цены', '/#cost')}
            {item('Портфолио', '/#projects')}
            {item('Отзывы', '/#feedback')}
            {item('Контакты', '/#contact')}
          </ul>
        </div>
        <div className={styles.main__social}>
          <div className={styles.footer__text}>Соц. сети</div>
          <ul>
            {item(
              'Instagram',
              'https://www.instagram.com/mdm_house_tgn/?igshid=1f90tqsdw09yv'
            )}
          </ul>
        </div>
      </div>
      <div className={styles.footer__main__logo_heart}>
        <span>
          &copy; {new Date().getFullYear()} made by{' '}
          <a href="http://justiceteam-it.com/">Justice Team</a> with
        </span>
        <Favorite className={styles.heartContainer__item} />
      </div>
    </footer>
  ) : (
    <footer className={styles.footer}>
      <div className={styles.footer__main__logo_mobile}>
        <img src={Logo} alt="MDM House company logo with text" />
      </div>
      <ListItem button onClick={handleClickLinks}>
        <ListItemText primary="Ссылки" className={styles.footer__title} />
      </ListItem>
      <Collapse in={openLinks} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {itemMobile('Главная', '/')}
          {itemMobile('О нас', '/#about')}
          {itemMobile('Цены', '/#cost')}
          {itemMobile('Портфолио', '/#projects')}
          {itemMobile('Отзывы', '/#feedback')}
          {itemMobile('Контакты', '/#contact')}
        </List>
      </Collapse>
      <ListItem button onClick={handleClickSocial}>
        <ListItemText primary="Соц. сети" className={styles.footer__title} />
      </ListItem>
      <Collapse in={openSocial} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {itemMobile(
            'Instagram',
            'https://www.instagram.com/mdm_house_tgn/?igshid=1f90tqsdw09yv'
          )}
        </List>
      </Collapse>
      <ListItem button onClick={handleClickContact}>
        <ListItemText primary="Контакты" className={styles.footer__title} />
      </ListItem>
      <Collapse in={!openContact} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {itemMobile('г. Таганрог, Россия')}
          {itemMobile('8 988 535 90 01')}
          <span className={styles.footer__email}>
            {' '}
            {itemMobile('mdmhouse@mail.ru')}
          </span>
        </List>
      </Collapse>
      <div>
        {itemMobile('ООО Натур')}
        {itemMobile('ИНН 6154153495')}
        {itemMobile('ОГРН 118619604097')}
        {itemMobile('Политика конфиденциальности','/politika-konfidenczialnosti/')}
      </div>
      <div className={styles.footer__main__logo_mobile}>
        <span>
          &copy; {new Date().getFullYear()} made by{' '}
          <a href="http://justiceteam-it.com/">Justice Team</a> with
        </span>
        <Favorite className={styles.heartContainer__item} />
      </div>
    </footer>
  )
}

export default Footer
