/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { YMInitializer } from 'react-yandex-metrika';

import Footer from './footerComponents/footer'
import NavbarMdm from './navbar/JusticeNavbar'
import Callback from './btnCallback/Callback'

import '../styles/default.css'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <div className='main-wrap'>
          <YMInitializer accounts={[71337346]} />
          <Callback />
          <NavbarMdm location={location} siteTitle="MDM House" />
          <main>{children}</main>
        </div>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
