import React, { useCallback, useLayoutEffect, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Collapse, List, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import * as classnames from 'classnames'
import PropTypes from 'prop-types'

import Logo from '../../assets/images/website-icon.png'

import './JusticeNavbar.scss'

const useStyles = makeStyles(() => ({
  root: {
    zIndex: '1000',
    height: '105vh',
    background: 'linear-gradient(0deg, #636262 0%, #a2a2a2 100%)',
    width: '25vh',
    marginLeft: '10px',
  },
}))

const NavbarMdm = ({ location }) => {
  const arrayLocation = location.pathname.split('/')
  const [navbarColor, setNavbarColor] = useState('navbar-transparent')
  const [openLinks, setOpenLinks] = useState(true)
  const classes = useStyles()
  const [windowWidth, setWindowWith] = useState(undefined)

  const handleClickLinks = () => {
    setOpenLinks(!openLinks)
  }

  const handleResize = useCallback(() => {
    setWindowWith(window.innerWidth)
  }, [])
  useLayoutEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  const mobileWidth = windowWidth && windowWidth < 1025

  const itemMobile = (title, link) => {
    if (link) {
      return (
        <Link to={link}>
          <ListItem>{title}</ListItem>
        </Link>
      )
    } else {
      return (
        <ListItem>
          <span>{title}</span>
        </ListItem>
      )
    }
  }

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 600 ||
        document.body.scrollTop > 600
      ) {
        setNavbarColor('')
      } else if (arrayLocation.includes('politika-konfidenczialnosti')) {
        setNavbarColor('')
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor('navbar-transparent')
      }
    }

    window.addEventListener('scroll', updateNavbarColor)

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor)
    }
  })

  const LinkArray = [
    {
      name: 'Главная',
      id: 1,
      href: '/',
    },
    {
      name: 'О нас',
      id: 2,
      href: '/#about',
    },
    {
      name: 'Цены',
      id: 3,
      href: '/#cost',
    },
    {
      name: 'Портфолио',
      id: 4,
      href: '/#projects',
    },
    {
      name: 'Отзывы',
      id: 5,
      href: '/#feedback',
    },
    {
      name: 'Контакты',
      id: 6,
      href: '/#contact',
    },
  ]

  return !mobileWidth ? (
    <header className={classnames('justice_header', navbarColor)}>
      <div className="justice_header__box">
        <div className="justice_header__box__img">
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <div className="justice_header__box_logo">
              <img alt="logo" src={Logo} className="justice_header__logo" />
            </div>
          </Link>
        </div>
        <div className="justice_header__box__link">
          {LinkArray.map((item) => (
            <div
              className="justice_header__link_hov"
              key={item.name + item.href}
            >
              <Link to={item.href} className="justice_header__link">
                <div className="justice_header__link_div">
                  <span className="justice_header__link_text">{item.name}</span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </header>
  ) : (
    <header className="mob_main">
      <div className="mob_main__boxes">
        <div className="mob_main__logo"/>
        <div className="mob_main__box_links">
          <div className="mob_main__box_link">
            <ListItem button onClick={handleClickLinks}>
              <MenuIcon />
            </ListItem>
          </div>
          <Collapse in={!openLinks} timeout="auto" unmountOnExit>
            <div className={classes.root}>
              <List
                component="div"
                disablePadding
                dense
                className="mob_main__box_text"
                onClick={handleClickLinks}
              >
                {itemMobile('Главная', '/')}
                {itemMobile('О нас', '/#about')}
                {itemMobile('Цены', '/#cost')}
                {itemMobile('Портфолио', '/#projects')}
                {itemMobile('Отзывы', '/#feedback')}
                {itemMobile('Контакты', '/#contact')}
              </List>
            </div>
          </Collapse>
        </div>
      </div>
    </header>
  )
}

NavbarMdm.propTypes = {
  siteTitle: PropTypes.string,
}

NavbarMdm.defaultProps = {
  siteTitle: ``,
}

export default NavbarMdm
